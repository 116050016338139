import * as monaco from "monaco-editor";
import playerImagePath from "../assets/player.png";
import platformImagePath from "../assets/tileset.png";
import haloImagePath from "../assets/halo.png";
import gemImagePath from "../assets/gem.png";
import propsImagePath from "../assets/props.png";
import mrPerciImagePath from "../assets/mrperci.png";

self.MonacoEnvironment = {
  getWorkerUrl: function (moduleId, label) {
    if (label === "json") {
      return "./json.worker.js";
    }
    if (label === "css" || label === "scss" || label === "less") {
      return "./css.worker.js";
    }
    if (label === "html" || label === "handlebars" || label === "razor") {
      return "./html.worker.js";
    }
    if (label === "typescript" || label === "javascript") {
      return "./ts.worker.js";
    }
    return "./editor.worker.js";
  },
};

import "./styles.css";




// Global game variables
let canvas,
  ctx,
  player,
  platform,
  keys,
  gravity,
  gameInterval,
  playerImage,
  mrPerciImage,
  haloImage,
  gemImage,
  propsImage,
  jumpingFrames,
  sprite,
  currentIdleFrame,
  currentRunningFrame,
  currentGemFrame,
  lastFrameIdelChangeTime,
  lastFrameRunningChangeTime,
  lastFrameGemChangeTime,
  idleFrames,
  runningFrames,
  keyEvents,
  platforms,
  currentFrame,
  currentSwitchFrame,
  platformImage,
  platformFrames,
  haloFrame,
  godMode,
  rainDrops,
  isRaining,
  magicRain,
  isWindy,
  crashTime,
  isCrashed,
  updateGame,
  windForce,
  windLines,
  maxWindForce,
  switchTime,
  magicSwitch,
  lightsOut,
  magicSwitchEngaged,
  magicSwitchRandomizer,
  showNumberDuration,
  elevatorWireLength,
  elevatorMoved,
  showNumber,
  elevator,
  mistParticles,
  startTime,
  lastGemTaken,
  hints,
  showHint,
  showHintInterval,
  showCrashHint,
  showMistHint,
  showRunningLeftHint,
  gameCode,
  fireworks,
  fireworkColors,
  text,
  switches,
  elevatorState,
  lastFrameIdleChangeTime,
  switchFrames,
  gemFrames,
  gems,
  gameStartTime;

// Initial game setup
function initializeGame() {
  canvas = document.getElementById("game-canvas");
  ctx = canvas.getContext("2d");


startTime = new Date(localStorage.getItem('startTime'));


  playerImage = new Image();
  playerImage.src = playerImagePath;
  platformImage = new Image();
  platformImage.src = platformImagePath;
  haloImage = new Image();
  haloImage.src = haloImagePath;
  gemImage = new Image();
  gemImage.src = gemImagePath;
  propsImage = new Image();
  propsImage.src = propsImagePath;
  mrPerciImage = new Image();
  mrPerciImage.src = mrPerciImagePath;

  currentIdleFrame = 0;
  currentRunningFrame = 0;
  currentGemFrame = 0;
  magicSwitch = false;
  magicSwitchEngaged = false;
  magicSwitchRandomizer = 0;
  showNumberDuration = 0;
  showNumber = 0;
  elevatorMoved = 0;
  elevatorState = false;
  elevatorWireLength = 0;
  showHint = false;
  showRunningLeftHint = false;
  showCrashHint = true;
  showMistHint = false;
  showHintInterval = 3;
  sprite = { x: 0, y: 0, width: 0, height: 0 };
  text = "Ivyynva  qrfpevcgvba: Na  Rivy Fzvyr";
  lastFrameIdleChangeTime = Date.now();
  lastFrameRunningChangeTime = Date.now();
  lastFrameGemChangeTime = Date.now();
  switchTime = Date.now();
  keyEvents = {};
  rainDrops = [];
  windLines = [];
  fireworks = [];
  mistParticles = [];
  switches = [];
  isRaining = false;
  magicRain = false;
  lightsOut = false;
  isWindy = true;
  crashTime = null;
  isCrashed = false;
  windForce = 0;

  hints = [
    'It  seems  like  the  game  crashes  when  it  starts  raining.!lb!Take  a  look  in  the  code  for  any  TODO  comments ',
    'Trouble  running  left?  Take  a  look  at the  Arrow  Left  code!lb!and  see  if  anything  is  out  commented',
    'Is  the  wind  bugging  you?  Maybe  you  can  change!lb!the  windforce  and  zero  the  wind  out',
    'You  might  be  able  to  use  the  wind  to  your  advantages.!lb!Either  let  you  glide  with  it  or  rise  you  upwards',
    'The  magic  switch  have  multiple  functions.!lb!Maybe  one  is  not  reached  in  the  current  switch/case  code?',
    'Gods  can  walk  through  clouds'
  ];

  switchFrames = [
    { x: 334, y: 161, width: 17, height: 16 },
    { x: 334, y: 177, width: 17, height: 16 },
  ];

  gemFrames = [
    { x: 0, y: 0, width: 14, height: 14 },
    { x: 15, y: 0, width: 14, height: 14 },
    { x: 30, y: 0, width: 14, height: 14 },
    { x: 45, y: 0, width: 14, height: 14 },
    { x: 60, y: 0, width: 14, height: 14 },
  ];

  platformFrames = [
    { x: 17, y: 17, width: 15, height: 15 },
    { x: 49, y: 17, width: 15, height: 15 },
    { x: 81, y: 17, width: 15, height: 15 },
    { x: 112, y: 17, width: 15, height: 15 },
    // Add more frames as needed
  ];

  idleFrames = [
    { x: 3, y: 10, width: 22, height: 22 },
    { x: 36, y: 10, width: 22, height: 22 },
    { x: 69, y: 10, width: 22, height: 22 },
    { x: 102, y: 10, width: 22, height: 22 },
  ];

  runningFrames = [
    { x: 3, y: 40, width: 22, height: 22 },
    { x: 36, y: 40, width: 22, height: 22 },
    { x: 69, y: 40, width: 22, height: 22 },
    { x: 102, y: 40, width: 22, height: 22 },
  ];

  jumpingFrames = [
    { x: 5, y: 168, width: 20, height: 20 },
    { x: 38, y: 168, width: 20, height: 20 },
  ];

  fireworkColors = [
    '#FF0000', // Red
    '#00FF00', // Green
    '#0000FF', // Blue
    '#FFFF00', // Yellow
    '#FF00FF', // Magenta
    '#00FFFF', // Cyan
    '#FFA500', // Orange
    '#8A2BE2'  // Blue Violet
  ];

  player = {
    x: 200,
    y: 800,
    width: 40,
    height: 40,
    speed: 5,
    jumpForce: 15,
    velY: 0,
    velX: 0,
    isJumping: false,
    isAirborne: false,
    isRunning: false,
    isIdle: true,
    direction: 1,
  };

  platforms = [
    { x: 0, y: 855, width: 1000, height: 40 },
    { x: 500, y: 780, width: 50, height: 40 },
    { x: 700, y: 700, width: 100, height: 40 },
    { x: 150, y: 600, width: 80, height: 40 },
    { x: 0, y: 500, width: 50, height: 40 },
    { x: 200, y: 460, width: 800, height: 40 },
    { x: 0, y: 240, width: 500, height: 40 },
    { x: 600, y: 240, width: 200, height: 40 },
    {
      x: 503,
      y: 250,
      width: 100,
      height: 40,
      elevatorEnd: 460,
      elevatorStart: 250,
    },
  ];

  gems = [
    { x: 735, y: 660, width: 28, height: 28, number: 2 },
    { x: 400, y: 420, width: 28, height: 28, number: 3 },
    { x: 50, y: 200, width: 28, height: 28, number: 4 },
  ];


  switches = [{ x: 700, y: 435, width: 28, height: 28 }];
  gravity = 0.8;
  keys = {};


  document.addEventListener("keydown", (e) => {
    keys[e.code] = true;
    keyEvents[e.code] = "keydown";
  });

  document.addEventListener("keyup", (e) => {
    keys[e.code] = false;
    keyEvents[e.code] = "keyup";
  });
}

lastGemTaken = localStorage.getItem('lastGemTaken');

if(lastGemTaken == null) {
  localStorage.setItem('lastGemTaken', new Date().toISOString());
  lastGemTaken = localStorage.getItem('lastGemTaken');
}

// base code
const baseCode = `

// Game loop
function updateGame() {
  // Clear the canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.imageSmoothingEnabled = false;


  if (!isRaining && Date.now() - gameStartTime > 3000 && Date.now() - gameStartTime < 3999) {
  ctx.fillStyle = 'rgba(100, 100, 100, 0.1)';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
  if (!isRaining && Date.now() - gameStartTime > 4000 && Date.now() - gameStartTime < 4999) {
  ctx.fillStyle = 'rgba(100, 100, 100, 0.2)';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
 if (!isRaining && Date.now() - gameStartTime > 5000) {
 ctx.fillStyle = 'rgba(100, 100, 100, 0.3)';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
 } else if(isRaining) {
  ctx.fillStyle = 'rgba(100, 100, 100, 0.3)';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
}


// Initialize wind lines
function createWindLine() {
  return {
    x: 800,
    y: Math.random() * canvas.height,
    length: 150,
    speed: 20 + Math.random() * 1,
  };
}

function createMistParticle() {
  let x = Math.random() * 400;
  let y = Math.random() * 220;

  return {
    x: x,
    y: y,
    spawnX: x, // Store the original spawn point
    spawnY: y,
    radius: 30 + Math.random() * 50, // Random radius size
    opacity: 0.2 + Math.random() * 0.3, // Random opacity
    speedX: -1.5 + Math.random(), // Horizontal speed
    speedY: -1.5 + Math.random(), // Vertical speed
  };
}

function createRaindrop() {
  return {
    x: Math.random() * canvas.width,
    y: Math.random() * 500,
    speed: 5 + Math.random() * 5,
    length: 15 + Math.random() * 15,
    windSpeed: 1 + Math.random() * 2,
    curvature: 0.1 + Math.random() * 0.2
  };
}

// Rain logic
if (!isRaining && Date.now() - gameStartTime > 5000 && !magicRain) {
  isRaining = true;
  crashTime = Date.now() + 3000;
}



// Check for crash
if (crashTime && Date.now() > crashTime && rainDrops.length > 100) {
  isCrashed = true;
}

ctx.fillStyle = 'rgba(150,75,76,255)';
ctx.fillRect(0, 870, 1000, 130);

  // If 7 minutes have passed since the start of the game or since the last gem was taken, show a hint
  if (Math.floor((Date.now() - new Date(lastGemTaken))) / 60000 >= showHintInterval) {

    ctx.fillStyle = 'rgba(255,255,255,255)';
    ctx.font = '22px CustomFont';
    ctx.fillText('Mr.  Perci', 10, 990);
    ctx.drawImage(mrPerciImage, 20, 900, 70, 70);

    if (showHint == false) {
      ctx.fillText('I  got  a  hint  for  you !  Press  H  to  show', 120, 950);

     if (keys['KeyH']) {
     showHint = true;
     }

    } else {
    let lines = '';
    if(showCrashHint) {
      lines = hints[0].split('!lb!');
    } else if (showRunningLeftHint == true && gems.length == 3) {
      lines = hints[1].split('!lb!');
    } else if (showMistHint) {
      lines = hints[5].split('!lb!');
    }
    else {
      lines = hints[gems[0].number].split('!lb!');
    }

    let x = 120;
    let y = 950;
    let lineHeight = 20;

    for (let i = 0; i < lines.length; i++) {
      ctx.fillText(lines[i], x, y + (i * lineHeight));
    }
  }

  }

// Crash logic
if (isCrashed) {
  rainDrops = [];
  windLines = [];
  mistParticles = [];
  ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = 'white';
  ctx.font = '48px CustomFont';
  ctx.fillText('GAME CRASHED', canvas.width / 2 - 150, canvas.height / 2);
  ctx.font = '32px CustomFont';
  ctx.fillText('Press  R  to  reset  the  game', (canvas.width / 2 - 185), (canvas.height / 2) + 50);
  
  if (keys['KeyR']) {
    console.log(keys);
    runGame();
  }
  return; // Stop updating the game
}

if(Date.now() - gameStartTime > 10000 && showCrashHint == true) {
  showCrashHint = false;
  showRunningLeftHint = true;
  localStorage.setItem('lastGemTaken', new Date().toISOString());
    lastGemTaken = localStorage.getItem('lastGemTaken');
  showHint = false;
}


  function checkMistCollision(player) {
    for (let particle of mistParticles) {
      let dx = player.x + player.width / 2 - particle.x;
      let dy = player.y + player.height / 2 - particle.y;
      let distance = Math.sqrt(dx * dx + dy * dy);
  
      if (distance < player.width / 2 + particle.radius && !godMode) {
        player.velX += 1;
      } 
    }
  }
  

  // Jumping
if (keys['Space'] && !player.isJumping && !player.isAirborne) {
  player.velY =- player.jumpForce;
  player.isJumping = true;
  player.isAirborne = true;
  player.isRunning = false;
  player.isIdle = true;
}


// Apply gravity

if(player.velY < -15) {
  player.velY =- player.jumpForce
}

 player.velY += gravity; 
 player.y += player.velY;
 player.x += player.velX; 



for (let i = 0; i < gems.length; i++) {
  let gem = gems[i];

  // Update the gem's frame every 130ms
  if (Date.now() - lastFrameGemChangeTime >= 130) {
    currentGemFrame = (currentGemFrame + 1) % gemFrames.length;
    lastFrameGemChangeTime = Date.now();
  }

  // Draw the gem
  let frame = gemFrames[currentGemFrame];
  ctx.drawImage(gemImage, frame.x, frame.y, frame.width, frame.height, gem.x, gem.y, gem.width, gem.height);

  // Check for a collision between the player and the gem
  if (checkCollision(player, gem)) {
    // Remove the gem
    gems.splice(i, 1);
    i--;
    localStorage.setItem('lastGemTaken', new Date().toISOString());
    lastGemTaken = localStorage.getItem('lastGemTaken');
    showHint = false;
    showCrashHint = false;
    showRunningLeftHint = false;
    showHintInterval = 7;
  }
}

// Draw each platform
for (let i = 0; i < platforms.length; i++) {
   platform = platforms[i];
   let frame = platformFrames[i % platformFrames.length ]; // Cycle through the frames
   if (platform.hasOwnProperty('elevatorStart')) {
    frame = { x: 177, y: 160, width: 15, height: 15 };
   }

  // Calculate the number of tiles needed
  let tiles = Math.ceil(platform.width / frame.width);

  // Draw each tile
  for (let j = 0; j < tiles; j++) {
    ctx.drawImage(platformImage, frame.x, frame.y, frame.width, frame.height, platform.x + j * frame.width, platform.y, frame.width, frame.height);
  }
  if (platform.hasOwnProperty('elevatorStart')) {
    if (magicSwitch) {
      if (platform.y != platform.elevatorEnd) {
        if (elevatorMoved < elevatorWireLength)
        platform.y += 1;
        elevatorMoved++;
      } else {
        elevatorMoved = 0;
      }
    } else {
      if (platform.y != platform.elevatorStart) {
        platform.y -= 1;
        elevatorMoved++;
      } else {
        elevatorMoved = 0;
      }
    }
  }
}

for (let platform of platforms) {
  if (checkPlatformCollision(player, platform)) {
    // Collision handling has been done inside the function
    break;
  }
}

updateSprite();
drawPlayer();

// Check for collisions
if (checkMistCollision(player)) {
  // Block player movement or take action when colliding with mist
}

// Collision detection function
function checkCollision(rect1, rect2) {
  return rect1.x < rect2.x + rect2.width &&
         rect1.x + rect1.width > rect2.x &&
         rect1.y < rect2.y + rect2.height &&
         rect1.y + rect1.height > rect2.y;
}

//Platform collision
function checkPlatformCollision(player, platform) {
  // Calculate the edges of the player and platform
  let playerLeft = player.x;
  let playerRight = player.x + player.width;
  let playerTop = player.y;
  let playerBottom = player.y + player.height;

  let platformLeft = platform.x;
  let platformRight = platform.x + platform.width;
  let platformTop = platform.y;
  let platformBottom = platform.y + platform.height;

  // Check if there's a collision at all
  if (playerRight > platformLeft && 
      playerLeft < platformRight && 
      playerBottom > platformTop && 
      playerTop < platformBottom) {

    // Calculate overlap on each axis
    let overlapX = Math.min(playerRight - platformLeft, platformRight - playerLeft);
    let overlapY = Math.min(playerBottom - platformTop, platformBottom - playerTop);

    // If the X overlap is smaller, it's a side collision
    if (overlapX < overlapY) {
      if (player.x < platform.x) {
        // Collision on the left side of the platform
        player.x = platformLeft - player.width;
      } else {
        // Collision on the right side of the platform
        player.x = platformRight;
      }
    } else {
      // It's a top/bottom collision
      if (player.y < platform.y) {
        // Collision from above
        player.y = platformTop - player.height;
        player.velY = 0; // Stop vertical movement
        player.velX = 0;
        player.isJumping = false;
        player.isAirborne = false;
      } else {
        // Collision from below
        player.y = platformBottom;
        player.velY = 0; // Stop vertical movement
        player.isJumping = false;
      }
    }
    return true; // Collision occurred
  }
  return false; // No collision
}

// Draw the switch
let switchFrame = switchFrames[magicSwitch ? 1 : 0];
ctx.drawImage(propsImage, switchFrame.x, switchFrame.y, switchFrame.width, switchFrame.height, switches[0].x, switches[0].y, switches[0].width, switches[0].height);

if (mistParticles.length < 100) {
  mistParticles.push(createMistParticle());
}


for (let particle of mistParticles) {
  ctx.beginPath();
  let gradient = ctx.createRadialGradient(
      particle.x, particle.y, 0,
      particle.x, particle.y, particle.radius
  );
  gradient.addColorStop(0, 'rgba(255, 255, 255, 0.7)');
  gradient.addColorStop(1, 'rgba(255, 255, 255, 0)'); // Fade out to transparent

  ctx.fillStyle = gradient;
  ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
  ctx.fill();

  // Move particles
  particle.x += particle.speedX;
  particle.y += particle.speedY;

  // If the particle is more than a few pixels away from its spawn point
  if (Math.abs(particle.x - particle.spawnX) > 5) {
      // Move the particle back towards its spawn point
      particle.x -= particle.speedX;
  }

  if (Math.abs(particle.y - particle.spawnY) > 5) {
      particle.y -= particle.speedY;
  }

}

function createFirework() {
  return {
    x: Math.random() * canvas.width,
    y: canvas.height,
    color: fireworkColors[Math.floor(Math.random() * fireworkColors.length)],
    radius: Math.random() * 3 + 1,
    velocity: Math.random() * 3 + 4,
    shards: [],
    exploded: false
  };
}

function drawFireworks() {
  // Add new fireworks
  if (Math.random() < 0.05) fireworks.push(createFirework());

  fireworks.forEach((fw, index) => {
    if (!fw.exploded) {
      // Move the firework up
      fw.y -= fw.velocity;

      // Draw the firework
      ctx.beginPath();
      ctx.arc(fw.x, fw.y, fw.radius, 0, Math.PI * 2);
      ctx.fillStyle = fw.color;
      ctx.fill();

      // Check if it's time to explode
      if (fw.y < Math.random() * canvas.height * 0.6) {
        fw.exploded = true;
        for (let i = 0; i < 30; i++) {
          fw.shards.push({
            x: fw.x,
            y: fw.y,
            vx: Math.random() * 6 - 3,
            vy: Math.random() * 6 - 3,
            radius: Math.random() * 2 + 1,
            alpha: 1
          });
        }
      }
    } else {
      // Draw and update shards
      fw.shards.forEach((shard, shardIndex) => {
        shard.x += shard.vx;
        shard.y += shard.vy;
        shard.vy += 0.1; // Gravity
        shard.alpha -= 0.02;

        ctx.beginPath();
        ctx.arc(shard.x, shard.y, shard.radius, 0, Math.PI * 2);
        ctx.fillStyle = fw.color + Math.floor(shard.alpha * 255).toString(16).padStart(2, '0');
        ctx.fill();

        if (shard.alpha <= 0) fw.shards.splice(shardIndex, 1);
      });

      // Remove the firework if all shards are gone
      if (fw.shards.length === 0) fireworks.splice(index, 1);
    }
  });
}

function masterOfDisguise(text) {
  return text.replace(/[a-zA-Z]/g, function(c){
    return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
  });
}

function drawWinPage() {
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.strokeStyle = 'gold';
  ctx.lineWidth = 5;
  ctx.strokeRect(100, 100, canvas.width - 200, canvas.height - 200);

  ctx.font = '24px CustomFont';
  ctx.fillStyle = 'white';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  let text2 = "Don't try to cheat!";
  
  if(gems.length == 0) {
  text2 = masterOfDisguise(text);
  }
  
  const lines = text2.split(':').map((line, index, array) => {
    return index < array.length - 1 ? line + ':' : line;
  });
  lines.forEach((line, index) => {
    ctx.fillText(line, canvas.width / 2, canvas.height / 2 - 50 + index * 30);
  });

  // Draw fireworks
  drawFireworks();
}

  if (gems.length === 0) {
    drawWinPage();
    return;
  }

`;

//Code the player can edit
const initialCode = `
godMode = false;

// Player actions
if (keys['ArrowLeft'] && keyEvents['ArrowLeft'] === 'keydown') {
    //player.x -= player.speed;
    player.direction = -1;
    player.isRunning = true;
    player.isIdle = false;
} else {
    player.isRunning = false;
    player.isIdle = true;
}

if (keys['ArrowRight']) {
    player.x += player.speed;
    player.direction = 1;
    player.isRunning = true;
    player.isIdle = false;
}

if (keys['Enter'] && Date.now() - switchTime > 500 && checkCollision(player, switches[0])) {
    switchTime = Date.now();
    if (magicSwitch) {
        magicSwitch = false;
    } else {
        magicSwitch = true;
        magicSwitchEngaged = true;
    }
}


// Apply wind force when jumping
if (player.isJumping && isWindy) {
    maxWindForce = 0.06;
    if (windForce < maxWindForce) {
        windForce -= 0.02;
    }
    player.velX += windForce;
} else {
    windForce = 0;
}


// Keep player in bounds
if (player.x <= 0 || player.x >= canvas.width) {
    player.velX = 0;
}
magicSwitchRandomizer = Math.floor(Math.random() * 3) + 1;
player.x = Math.max(0, Math.min(canvas.width - player.width, player.x));
player.y = Math.min(canvas.height - player.height, player.y);


if (isRaining) {
    // TODO: Older systems might crash with too many rainDrops
    if (rainDrops.length < 9999) {
        rainDrops.push(createRaindrop());
    }

    // Update and draw rainDrops
    ctx.strokeStyle = 'lightblue';
    ctx.lineWidth = 1;
    for (let i = rainDrops.length - 1; i >= 0; i--) {
        let drop = rainDrops[i];
        drop.y += drop.speed;
        drop.x -= drop.windSpeed; // Move the raindrop horizontally

        ctx.beginPath();
        ctx.moveTo(drop.x, drop.y);
        ctx.lineTo(drop.x - drop.windSpeed, drop.y + drop.length);
        ctx.stroke();

        // Remove rainDrops that are off-screen
        if (drop.y > canvas.height || drop.x > canvas.width) {
            rainDrops.splice(i, 1);
        }
    }
}

if (isWindy) {
    // Update and draw wind lines
    if (windLines.length < 10) {
        windLines.push(createWindLine());
    }

    ctx.strokeStyle = 'rgba(211,211,211,0.4)';
    ctx.lineWidth = 3;

    for (let i = windLines.length - 1; i >= 0; i--) {
        let line = windLines[i];

        // Update the position of each wind line
        line.x -= line.speed;


        // Draw the wind line
        ctx.beginPath();
        ctx.moveTo(line.x, line.y);
        ctx.lineTo(line.x + line.length, line.y);
        ctx.stroke();
        // Remove windLines that are off-screen
        if (line.x + line.length < 0) {
            windLines.splice(i, 1);
        }

    }
}



if (magicSwitchEngaged) {
    showNumberDuration = 100;
    switch (magicSwitchRandomizer) {
        case 1:
            showNumber = 1;
            if (magicSwitch && isRaining) {
                isRaining = false;
                magicRain = true;
                rainDrops = [];
            } else if (magicSwitch) {
                isRaining = true;
                magicRain = false;
            }
            magicSwitchEngaged = false;
            break;
        case 2:
            showNumber = 2;
            if (magicSwitch && isWindy) {
                isWindy = false;
                windLines = [];
            } else if (magicSwitch) {
                isWindy = true;
            }
            magicSwitchEngaged = false;
            break;

        case 3:
            showNumber = 3;
            if (magicSwitch && !lightsOut) {
                lightsOut = true;
            } else if (magicSwitch) {
                lightsOut = false;
            }
            magicSwitchEngaged = false;
            break;
        case 4:
            showNumber = 4;
            if (magicSwitch) {
                elevatorWireLength = 5;
            }
            magicSwitchEngaged = false;
            break;
    }
}

if (showNumberDuration > 0) {
    if (showNumber == 3) {
        ctx.fillStyle = 'white';
    } else {
        ctx.fillStyle = 'black';
    }
    ctx.font = '32px CustomFont';
    ctx.fillText(showNumber.toString(), 710, 415);
    showNumberDuration--;
}

// Draw player
// Update the sprite every frame
function updateSprite() {
  if (player.isJumping || player.isAirborne) {
    sprite = player.velY < 0 ? jumpingFrames[0] : jumpingFrames[1];
    
    } else if (player.isRunning && Date.now() - lastFrameRunningChangeTime >= 80)  {
        sprite = runningFrames[currentRunningFrame];
        currentRunningFrame = (currentRunningFrame + 1) % runningFrames.length;
        lastFrameRunningChangeTime = Date.now();
      }
      else if (player.isIdle && Date.now() - lastFrameIdleChangeTime >= 130) {
        sprite = idleFrames[currentIdleFrame];
        currentIdleFrame = (currentIdleFrame + 1) % idleFrames.length;
        lastFrameIdleChangeTime = Date.now();
     
    }
  }

  function drawPlayer() {
    // If the player is running left, draw the image flipped
    if (player.direction === -1) {
      ctx.save();
      ctx.translate(player.x + player.width, player.y);
      ctx.scale(-1, 1);
      ctx.drawImage(playerImage, sprite.x, sprite.y, sprite.width, sprite.height, 0, 0, player.width, player.height);
      ctx.restore();
    } else {
      // Otherwise, draw the image normally
      ctx.drawImage(playerImage, sprite.x, sprite.y, sprite.width, sprite.height, player.x, player.y, player.width, player.height);
    }
    if (godMode == true)
     {
    ctx.drawImage(haloImage, player.x + 12, player.y - 20, 26, 26);
     }
  
  }

`;

const endCode = `
if(gems.length == 1 && elevatorWireLength >= 80 && showMistHint == false) {
  showRunningLeftHint = false;
  showCrashHint = false
  showMistHint = true;
  localStorage.setItem('lastGemTaken', new Date().toISOString());
    lastGemTaken = localStorage.getItem('lastGemTaken');
  showHint = false;
}

if (lightsOut) {
  ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
}

}`;

// Initialize the game
initializeGame();

// Set up Monaco Editor
let editor = monaco.editor.create(document.getElementById("editor"), {
  value: initialCode,
  language: "javascript",
  theme: "vs-dark",
  fontSize: 14,
  minimap: { enabled: false },
  contextmenu: false,
});

function runGame() {
  clearInterval(gameInterval);
  const gameCode = `
    (function() {
      if(!editor.getValue().includes("//player.x") && showRunningLeftHint == true) {
        showRunningLeftHint = false;
        showCrashHint = false
        showHint = false;
        localStorage.setItem('lastGemTaken', new Date().toISOString());
        lastGemTaken = localStorage.getItem('lastGemTaken');
      }
      ${baseCode}
      ${editor.getValue()}
      ${endCode}
      return updateGame;
    })()
  `;
  try {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (isCrashed) {
      player.x = 200;
      player.y = 800;
      rainDrops = [];
      windLines = [];
      mistParticles = [];
      isRaining = false;
    }
    crashTime = null;
    isCrashed = false;
    gameStartTime = Date.now();
    const updateGame = eval(gameCode);
    gameInterval = setInterval(updateGame, 1000 / 60);
  } catch (error) {
    console.error("Game code error:", error);
  }
}
// Run the game initially
playerImage.onload = function () {
  // The image has finished loading, now we can run the game
  runGame();
};
playerImage.onerror = function () {
  console.error("Error loading image");
};

// Run the game on each change (with a small delay to avoid constant re-evaluation)
let debounceTimer;
editor.onDidChangeModelContent(() => {
  clearTimeout(debounceTimer);
  debounceTimer = setTimeout((runGame), 1000); // 500ms delay
});
